import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
// import { graphql } from "gatsby";
//import Img from "gatsby-image"
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import * as style from "./index.module.css";
import { Col, Container, Row } from "react-bootstrap";

// P5
import Sketch from "../components/sketch";
import sketch from "../components/scripts/ramifications";

const PageHeader = styled.h1`
  text-align: center;
`;

const NewsPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="art in common"
        description="news from Artincommon"
        // image="/logo.png"
        pathname="/news"
        // Boolean indicating whether this is an article:
        // article
      />

      <Sketch sketch={sketch} />

      <PageHeader className={style.heading}>
        <div>News from Art In Common</div>
      </PageHeader>

      

      <Container  fluid="sm" className="mt-4">
        <Row>
          <Col lg={4}>
          <section className={style.translated}>
          <div>
         NEWS
          </div>
        </section>
      
          </Col>

          <Col lg={4}>
        <section className={style.entertext}>
          <div>
            {" "}
           
          </div>
        </section>
        </Col>
        </Row>
   
      </Container>

     
    </Layout>
  );
};

export default NewsPage;

// export const query = graphql`
//   {
//     headerImage: file(
//       relativePath: { eq: "robots-androids-and-cyborgs-oh-my-1184x360.jpg" }
//     ) {
//       childImageSharp {
//         fluid(maxWidth: 1200) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;
